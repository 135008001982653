<template>
	<div :class="absolute ? 'absolute server-status' : 'server-status'">
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-progress-circular
					:rotate="360"
					:size="40"
					:width="5"
					:value="server.cpu"
					:color="getProgressColor(server.cpu)"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon :color="getProgressColor(server.cpu)">mdi-memory</v-icon>
				</v-progress-circular>
			</template>
			<b>CPU Usage:</b> {{ server.cpu }}%
		</v-tooltip>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-progress-circular
					class="stat-percent"
					:rotate="360"
					:size="40"
					:width="5"
					:value="server.mem"
					:color="getProgressColor(server.mem)"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon size="17" :color="getProgressColor(server.mem)"
						>mdi-server</v-icon
					>
				</v-progress-circular>
			</template>
			<b>Memory Usage:</b> {{ server.mem }}%
		</v-tooltip>

		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-progress-circular
					class="stat-percent"
					:rotate="360"
					:size="40"
					:width="5"
					:value="server.drive"
					:color="getProgressColor(server.drive)"
					v-bind="attrs"
					v-on="on"
				>
					<v-icon size="19" :color="getProgressColor(server.drive)"
						>mdi-harddisk</v-icon
					>
				</v-progress-circular>
			</template>
			<b>Disk Usage:</b> {{ server.drive }}%
		</v-tooltip>
	</div>
</template>

<script>
export default {
	name: 'StatusServerInfo',
	props: {
		server: {
			type: Object,
			required: true,
		},
		absolute: {
			type: Boolean,
		},
	},
	methods: {
		getProgressColor(percent) {
			if (percent < 50) {
				return 'success';
			} else if (percent < 85) {
				return 'secondary';
			} else {
				return 'error';
			}
		},
	},
};
</script>

<style scoped lang="scss">
.server-status {
	display: flex;
}

.absolute {
	position: absolute;
	right: 30px;
	top: 25px;
}

.stat-percent {
	margin-left: 6px;
}
</style>
