<template>
	<div class="log scroll">
		<ParticleBackground fullscreen />
		<v-card class="owo-status">
			<v-card-title class="title"> OwO Bot Status </v-card-title>
			<v-btn large icon class="refresh-button" @click="fetchStatus">
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
			<StatusSearch :info="servers" @update="fetchStatus" />
			<div class="service-status">
				<StatusCard
					v-for="server in serviceServers"
					:key="server.type + server.min"
					:title="server.name"
					:info="server"
					service
				/>
			</div>
			<StatusCard
				v-for="server in owoServers"
				:key="server.type + server.min"
				:title="server.name"
				:info="server"
			/>
		</v-card>
	</div>
</template>

<script>
import ParticleBackground from '@/components/particles/ParticleBackground';
import StatusCard from '@/views/status/components/StatusCard';
import StatusSearch from '@/views/status/components/StatusSearch';

export default {
	name: 'StatusPage',
	components: { StatusCard, StatusSearch, ParticleBackground },
	data: () => ({
		servers: [],
	}),
	computed: {
		owoServers() {
			return this.servers
				.filter((server) => server.type === 'owo')
				.sort((a, b) => a.sort - b.sort);
		},
		serviceServers() {
			return this.servers.filter((server) => server.type !== 'owo');
		},
	},
	watch: {},
	methods: {
		async fetchStatus() {
			const status = await this.$store.dispatch('getStatus');
			status.forEach((e) => {
				this.sanitizeServer(e);
			});
			this.servers = status;
		},
		sanitizeServer(e) {
			e.mem = Math.floor(e.mem * 10) / 10;
			switch (e.type) {
				case 'owo':
					this.sanitizeOwo(e);
					break;
				case 'mysql':
					e.name = 'MySQL Database';
					break;
				case 'redis':
					e.name = 'Redis Database';
					break;
				case 'gen':
					e.name = 'Image Generation';
					break;
			}
		},
		sanitizeOwo(e) {
			e.name = 'OwO Server ' + (e.sharder + 1);
			e.sort = 1 + e.sharder;
			e.uptime = e.start;
			for (let i in e.shards) {
				const shard = e.shards[i];
				shard.id = shard.shard;
				const prevShard = e.shards[i - 1];
				if (prevShard) {
					if (prevShard.cluster !== shard.cluster) {
						shard.newCluster = true;
					}
				}
			}
		},
	},
	async mounted() {
		await this.fetchStatus();
	},
};
</script>

<style scoped lang="scss">
.owo-status {
	margin: 50px 0px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--v-background-base);
	border: 3px solid var(--v-primary-base);
}

.service-status {
	display: flex;
	align-items: center;
}

.log {
	position: relative;
	min-height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll::-webkit-scrollbar-track {
	background: var(--v-background_dark2-base);
	border-left: 1px solid var(--v-background-base);
}

.scroll::-webkit-scrollbar-thumb {
	background: var(--v-background-base);
	border: solid 3px var(--v-background_dark2-base);
	border-radius: 7px;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: var(--v-secondary-base);
}

.title {
	padding: 5px;
	padding-top: 15px;
	font-size: 30px !important;
}

.refresh-button {
	position: absolute;
	top: 30px;
	right: 30px;
}
</style>
