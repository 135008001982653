<template>
	<div class="search">
		<v-text-field
			clearable
			label="Search by Server ID"
			prepend-inner-icon="mdi-magnify"
			:rules="rules"
			v-model="search"
		></v-text-field>
		<v-card v-if="shard" class="status-card" :style="cardBorderColor" outlined>
			<StatusServerInfo :server="shard.server" absolute />
			<v-card-title> Server ID: {{ search }} </v-card-title>
			<v-card-subtitle> Shard #{{ shard.shard.id }} </v-card-subtitle>
			<v-card-text>
				<b>Shard Status: </b>{{ shard.shard.status }}<br />
				<b>Shard Ping: </b>{{ shard.shard.ping }}<br />
				<b>Shard Cluster: </b>{{ shard.shard.cluster }}<br />
				<b>Shard Uptime: </b>{{ toUptime(shard.shard.start) }}<br />
				<b>Last Updated: </b>{{ toUpdatedOn(shard.shard.updatedOn) }}<br />
				<b>Lives on: </b>{{ shard.server.name }}
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import StatusServerInfo from '@/views/status/components/StatusServerInfo';

export default {
	name: 'StatuSearch',
	components: { StatusServerInfo },
	data: () => ({
		search: null,
		rules: [
			(v) => !v || (v.length >= 17 && v.match(/^\d+$/gi)) || 'Invalid guild id',
		],
		justFetched: false,
	}),
	props: {
		info: {
			type: Array,
			required: true,
		},
	},
	computed: {
		shard() {
			if (!this.search || typeof this.rules[0](this.search) === 'string') {
				return null;
			}
			const max = this.getMaxShards();
			const shardId = this.getShardId(max);
			const shard = this.getShard(shardId);
			return shard;
		},
		cardBorderColor() {
			if (this.shard) {
				const shard = this.shard.shard;
				const ping = shard.ping;
				if (this.isOldShard(shard)) {
					return 'border: 3px solid var(--v-error-base)';
				} else if (ping >= 100) {
					return 'border: 3px solid var(--v-secondary-base)';
				} else {
					return 'border: 3px solid var(--v-success-base)';
				}
			} else {
				return 'border: 3px solid var(--v-error-base)';
			}
		},
	},
	watch: {},
	methods: {
		toUpdatedOn(date) {
			if (!date) return;
			const diff = new Date() - new Date(date);
			return this.toUptime(diff) + ' ago';
		},
		toUptime(ms) {
			if (!ms) return;
			ms = Math.floor(ms / 1000);
			const seconds = ms % 60;
			ms = Math.floor(ms / 60);
			if (ms <= 0) {
				return `${seconds}s`;
			}
			const minutes = ms % 60;
			ms = Math.floor(ms / 60);
			if (ms <= 0) {
				return `${minutes}m`;
			}
			const hours = ms % 24;
			ms = Math.floor(ms / 24);
			if (ms <= 0) {
				return `${hours}h`;
			}
			const days = ms;
			return `${days}d`;
		},
		isOldShard(shard) {
			if (!shard) return false;
			const diff = new Date() - new Date(shard.updatedOn);
			const isOld = diff > 1000 * 60 * 2.5;
			if (isOld && !this.justFetched) {
				this.justFetched = true;
				setTimeout(() => {
					this.justFetched = false;
				}, 15000);
				this.$emit('update');
			}
			return isOld;
		},
		getMaxShards() {
			let max = 0;
			this.info.forEach((server) => {
				if (server.max && server.max > max) {
					max = server.max;
				}
			});
			return max + 1;
		},
		getShardId(maxShards) {
			const guildId = parseInt(this.search);
			return parseInt(guildId.toString(2).slice(0, -22), 2) % maxShards;
		},
		getServer(shardId) {
			for (let i in this.info) {
				const server = this.info[i];
				if (server.max && server.min) {
					if (server.min <= shardId && shardId <= server.max) {
						return server;
					}
				}
			}
		},
		getShard(shardId) {
			const server = this.getServer(shardId);
			if (!server) return null;
			for (let i in server.shards) {
				const shard = server.shards[i];
				if (shard.id === shardId) {
					return { server, shard };
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
.search {
	width: 100%;
	max-width: 600px;
	padding: 0px;
	margin: 15px;
}

.status-card {
	width: 100%;
	max-width: 600px;
	padding: 10px;
	margin: 0px;
	background-color: rgba(0, 0, 0, 0.5);
}
</style>
