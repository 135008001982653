<template>
	<v-card
		:class="isShards ? 'status-card' : 'service-card'"
		:style="getStyle"
		outlined
	>
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-subtitle>
			{{ subtitle }}
		</v-card-subtitle>
		<v-card-text v-if="!isShards" class="service-status">
			<StatusServerInfo :server="info" />
		</v-card-text>
		<StatusServerInfo v-if="isShards" :server="info" absolute />
		<v-card-text class="card-body">
			<div v-if="isShards" class="shard-divs">
				<span v-for="shard in shards" :key="shard.id">
					<v-tooltip right>
						<template v-slot:activator="{ on, attrs }">
							<div
								:class="'shard ' + getShardClass(shard)"
								v-bind="attrs"
								v-on="on"
							></div>
						</template>
						<b>Shard: </b>{{ shard.id }}<br />
						<b>Status: </b>{{ getShardStatus(shard.ping) }}<br />
						<span v-if="shard.ping >= 0">
							<b>Ping: </b>{{ shard.ping }}<br />
							<b>Uptime: </b>{{ shard.uptime }}<br />
						</span>
						<b>Updated: </b>{{ shard.updatedOn }}
					</v-tooltip>
				</span>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import StatusServerInfo from '@/views/status/components/StatusServerInfo';

export default {
	name: 'StatusCard',
	components: { StatusServerInfo },
	data: () => ({
		total: 0,
		offline: 0,
		online: 0,
	}),
	props: {
		title: {
			type: String,
			required: true,
		},
		info: {
			type: Object,
			required: true,
		},
		service: {
			type: Boolean,
		},
	},
	computed: {
		shardCount() {
			if (this.info.min && this.info.max) {
				return this.info.max - this.info.min + 1;
			}
			return 0;
		},
		subtitle() {
			if (this.isOld) {
				return 'OFFLINE';
			} else if (this.isShards) {
				return `Shards ${this.info.min}-${this.info.max}`;
			}
			return null;
		},
		isShards() {
			return this.info.type === 'owo';
		},
		getStyle() {
			let style = this.cardBorderColor + '; ';
			if (this.isOld) {
				style += 'opacity: 0.4;';
			}
			return style;
		},
		isOld() {
			return Date.now() - this.info.updated > 3 * 60 * 1000;
		},
		cardBorderColor() {
			if (this.isShards) {
				const shardPercent = this.online / this.total;
				if (shardPercent > 0.9) {
					return 'border: 3px solid var(--v-success-base)';
				} else if (shardPercent > 0.75) {
					return 'border: 3px solid var(--v-secondary-base)';
				} else {
					return 'border: 3px solid var(--v-error-base)';
				}
			} else {
				const totalAvg = (this.info.mem + this.info.cpu) / 2;
				if (this.info.drive > 95) {
					return 'border: 3px solid var(--v-error-base)';
				} else if (totalAvg > 95) {
					return 'border: 3px solid var(--v-error-base)';
				} else if (totalAvg > 75) {
					return 'border: 3px solid var(--v-secondary-base)';
				} else {
					return 'border: 3px solid var(--v-success-base)';
				}
			}
		},
		shards() {
			this.total = 0;
			this.online = 0;
			this.offline = 0;
			if (!this.isShards) return;
			const shards = [];
			for (let i = this.info.min; i <= this.info.max; i++) {
				let shard = this.info.shards.find((shard) => shard.id === i);
				if (this.isOldShard(shard)) {
					shard = null;
				}
				let ping = -1;
				if (typeof shard?.ping === 'number') {
					ping = shard?.ping;
				}
				shards.push({
					id: i,
					ping: ping,
					cluster: shard?.cluster,
					newCluster: shard?.newCluster,
					updatedOn: this.toUpdatedOn(shard?.updatedOn),
					uptime: this.toUptime(shard?.start),
				});

				if (shard) {
					this.online++;
				} else {
					this.offline++;
				}
				this.total++;
			}
			return shards;
		},
	},
	watch: {},
	methods: {
		isOldShard(shard) {
			if (!shard) return false;
			const diff = new Date() - new Date(shard.updatedOn);
			return diff > 1000 * 60 * 2.5;
		},
		toUpdatedOn(date) {
			if (!date) return;
			const diff = new Date() - new Date(date);
			return this.toUptime(diff) + ' ago';
		},
		toUptime(ms) {
			if (!ms) return;
			ms = Math.floor(ms / 1000);
			const seconds = ms % 60;
			ms = Math.floor(ms / 60);
			if (ms <= 0) {
				return `${seconds}s`;
			}
			const minutes = ms % 60;
			ms = Math.floor(ms / 60);
			if (ms <= 0) {
				return `${minutes}m`;
			}
			const hours = ms % 24;
			ms = Math.floor(ms / 24);
			if (ms <= 0) {
				return `${hours}h`;
			}
			const days = ms;
			return `${days}d`;
		},
		getShardStatus(ping) {
			if (ping < 0) {
				return 'offline';
			} else if (ping <= 100) {
				return 'online';
			} else {
				return 'lagging';
			}
		},
		getShardClass(shard) {
			let newCluster = '';
			if (shard.newCluster) {
				newCluster = ' new-cluster';
			}
			if (shard.ping < 0) {
				return 'shard-offline' + newCluster;
			} else if (shard.ping <= 100) {
				return 'shard-online' + newCluster;
			} else {
				return 'shard-lag' + newCluster;
			}
		},
		getProgressColor(percent) {
			if (percent < 50) {
				return 'success';
			} else if (percent < 85) {
				return 'secondary';
			} else {
				return 'error';
			}
		},
	},
};
</script>

<style scoped lang="scss">
.status-card {
	width: 100%;
	max-width: 600px;
	padding: 10px;
	margin: 15px;
	background-color: rgba(0, 0, 0, 0.2);
}

.service-card {
	margin: 6px;
	background-color: rgba(0, 0, 0, 0.2);
}

.shard-divs {
	display: flex;
	flex-wrap: wrap;
}

.shard {
	width: 5px;
	height: 25px;
	margin: 1px;
}

.shard-offline {
	background-color: var(--v-error-base);
}

.shard-online {
	background-color: var(--v-success-base);
}

.shard-lag {
	background-color: var(--v-secondary-base);
}

.new-cluster {
	margin-left: 1px;
}

.stat-percent {
	margin-left: 6px;
}

.card-body {
	margin-top: -23px;
}

.service-status {
	width: 100%;
	display: flex;
	justify-content: center;
}
</style>
